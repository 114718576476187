<template>
  <div
    class="fixed z-20 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    :class="show ? 'block' : 'hidden'"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-secondary bg-opacity-75 transition-opacity"
        @click="$emit('onCancel')"
      />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >&#8203;</span>

      <div
        class="inline-block align-bottom bg-secondary-darker border border-secondary rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6"
        :class="modalWidth"
      >
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            class="rounded-md text-secondary-light hover:text-secondary-lighter focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-light"
            @click="$emit('onCancel')"
          >
            <span class="sr-only">Schließen</span>
            <BIconXLg class="h-5 font-bold" />
          </button>
        </div>
        <div class="sm:flex sm:items-start w-full">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full sm:mx-0"
            :class="iconClass"
          >
            <div class="mx-auto">
              <slot name="icon" class="mx-auto w-16 w-16" />
            </div>
          </div>
          <div class="mt-2 text-center sm:mx-4 sm:text-left w-full">
            <!-- titel -->
            <h3 class="text-lg leading-6 text-white font-bold">
              {{ titel }}
            </h3>
            <!-- content -->
            <div class="mt-2 text-center sm:text-left">
              <!-- content -->
              <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <slot name="content" />
              </div>

              <!-- action buttons -->
              <div class="sm:flex sm:items-start">
                <div
                  class="mt-5 sm:mt-4 col-span-3 sm:flex sm:flex-row-reverse ml-auto"
                >
                  <button
                    class="btn-standard-no-color shadow-sm px-4 py-2 sm:ml-3"
                    type="button"
                    :class="buttonConfirmClass"
                    :disabled="buttonConfirmDisabled"
                    @click="$emit('onConfirm')"
                  >
                    {{ buttonConfirmText }}
                    <Spinner v-if="buttonConfirmSpinner" class="ml-2 w-4 h-4" />
                  </button>
                  <button
                    class="btn-standard-no-color mt-3 shadow-sm px-4 py-2 sm:mt-0"
                    type="button"
                    :class="buttonCancelClass"
                    @click="$emit('onCancel')"
                  >
                    {{ buttonCancelText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BIconXLg } from 'bootstrap-icons-vue'
import Spinner from '~/components/shared/basics/Spinner.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'info'
  },
  show: {
    type: Boolean,
    required: true
  },
  titel: {
    type: String,
    required: true
  },
  buttonCancelText: {
    type: String,
    default: 'Abbrechen'
  },
  buttonConfirmText: {
    type: String,
    default: 'Bestätigen'
  },
  buttonCancelClass: {
    type: String,
    default:
      'border-secondary-light bg-secondary text-common-white hover:text-secondary-lighter'
  },
  buttonConfirmClass: {
    type: String,
    default:
      'bg-lilac text-common-white hover:bg-lilac-dark focus:ring-lilac disabled:bg-secondary'
  },
  buttonConfirmSpinner: {
    type: Boolean,
    default: false
  },
  buttonConfirmDisabled: {
    type: Boolean,
    default: false
  },
  iconClass: {
    type: String,
    default: 'text-common-white bg-lilac'
  },
  modalWidth: {
    type: String,
    default: 'max-w-2xl'
  }
})
defineEmits(['onCancel', 'onConfirm'])

const currentColorClass = ref('')
const currentColorClassLight = ref('')
const currentColorClassButton = ref('')

onMounted(() => {
  switch (props.type) {
    case 'warning':
      currentColorClass.value = 'text-special-red-text'
      currentColorClassButton.value = 'bg-special-red-bg'
      currentColorClassLight.value = 'bg-special-red-bg'
      break
    case 'info':
      currentColorClass.value = 'text-lilac-light'
      currentColorClassButton.value =
        'bg-lilac-light hover:bg-lilac focus:ring-lilac-light'
      currentColorClassLight.value = 'bg-secondary-light'
  }
})
</script>

<style scoped></style>
